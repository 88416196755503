$black: #000;
$darkGray: #444;
$gray2: #505970;
$light: #f5f6fa;
$lightGray: #edeef2;
$gray3: #eef1f8;
$gray4: #cfd3dd;
$darkRed: #9c0c24;
$white: #fff;

/* cards color */
$red: #d32f2f;
$amber: #c2185b;
$purple: #7b1fa2;
$violet: #512da8;
$primary: #303f9f;
$blue: #1976d2;
$sky: #0288d1;
$cyan: #0097a7;
$teal: #00796b;
$grass: #388e3c;
$green: #689f38;
$lime: #c0ca33;
$yellow: #fdd835;
$amber: #ffa000;
$orange: #f57c00;
$brick: #e64a19;
$gray: #616161;
$dark: #222;

/* COLORS CONSTANTS */
/* background colors */
$ui-bg-dark: $dark;
$ui-bg-gray: $lightGray;
$ui-bg-blue-light: $blue;
$ui-bg-light: $light;
$ui-bg-white: $white;

/* button colors */
$ui-btn-blue: $blue;
$ui-btn-dark: $darkGray;
$ui-btn-orange: $darkRed;
$ui-btn-primary: $primary;
$ui-btn-white: $white;

/* gradient colors */
$ui-grad-start: $dark;
$ui-grad-end: $dark;

/* icon colors */
$ui-icon-amber: $darkRed;
$ui-icon-gray: $gray;
$ui-icon-light: $light;
$ui-icon-menu: $gray;
$ui-icon-primary: $primary;
$ui-icon-red: $darkRed;
$ui-icon-orange: $darkRed;
$ui-icon-white: $white;
$ui-icon-dark: $dark;

/* line colors */
$ui-line-dark: $dark;
$ui-line-gray: $lightGray;
$ui-line-header: $darkGray;
$ui-line-table: $light;

/* shadow colors */
$ui-shadow: $darkGray;
$ui-shadow-table: $lightGray;

/* text colors */
$ui-text-black: $black;
$ui-text-amber: $darkRed;
$ui-text-blue: $blue;
$ui-text-dark-blue: $darkRed;
$ui-text-dark: $dark;
$ui-text-gray: $gray;
$ui-text-green: $grass;
$ui-text-dark-gray: $gray;
$ui-text-light: $light;
$ui-text-menu: $gray;
$ui-text-orange: $darkRed;
$ui-text-primary: $primary;
$ui-text-red: $darkRed;
$ui-text-search: $darkGray;
$ui-text-soft-blue: $darkRed;
$ui-text-white: $white;
$ui-text-dark2: $dark;

/* bg transparent colors */
$ui-bg-modal: rgba($black, 0.65);
$ui-bg-hover: rgba($black, 0.35);
$ui-bg-slide: rgba($white, 0.45);
$ui-bg-switch: rgba($black, 0.15);
$ui-bg-title: rgba($dark, 0.65);
$ui-btn-pink-light: rgba($amber, 0.15);
$ui-shadow: rgba($black, 0.35);
