@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.check-button {
  position: relative;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 32px;
  padding: 0 20px;
  border-radius: 20px;
  border: 1px solid $ui-line-gray;
  background-color: $ui-btn-white;

  span {
    color: $ui-text-dark;
    font-size: 14px;
    font-weight: 500;
  }

  &.active {
    &.button-red {
      border: 1px solid $red!important;
      background-color: $red!important;

      span {
        color: $ui-text-white;
      }
    }

    &.button-pink {
      border: 1px solid $amber!important;
      background-color: $amber!important;

      span {
        color: $ui-text-white;
      }
    }

    &.button-purple {
      border: 1px solid $purple!important;
      background-color: $purple!important;

      span {
        color: $ui-text-white;
      }
    }

    &.button-violet {
      border: 1px solid $violet!important;
      background-color: $violet!important;

      span {
        color: $ui-text-white;
      }
    }

    &.button-primary {
      border: 1px solid $primary;
      background-color: $primary;

      span {
        color: $ui-text-white;
      }
    }

    &.button-blue {
      border: 1px solid $blue;
      background-color: $blue;

      span {
        color: $ui-text-white;
      }
    }

    &.button-sky {
      border: 1px solid $sky!important;
      background-color: $sky!important;

      span {
        color: $ui-text-white;
      }
    }

    &.button-cyan {
      border: 1px solid $cyan!important;
      background-color: $cyan!important;

      span {
        color: $ui-text-white;
      }
    }

    &.button-teal {
      border: 1px solid $teal;
      background-color: $teal;

      span {
        color: $ui-text-white;
      }
    }

    &.button-grass {
      border: 1px solid $grass;
      background-color: $grass;

      span {
        color: $ui-text-white;
      }
    }

    &.button-green {
      border: 1px solid $green;
      background-color: $green;

      span {
        color: $ui-text-white;
      }
    }

    &.button-lime {
      border: 1px solid $lime;
      background-color: $lime;

      span {
        color: $ui-text-white;
      }
    }

    &.button-yellow {
      border: 1px solid $yellow;
      background-color: $yellow;

      span {
        color: $ui-text-white;
      }
    }

    &.button-amber {
      border: 1px solid $amber;
      background-color: $amber;

      span {
        color: $ui-text-white;
      }
    }

    &.button-orange {
      border: 1px solid $orange;
      background-color: $orange;

      span {
        color: $ui-text-white;
      }
    }

    &.button-brick {
      border: 1px solid $brick;
      background-color: $brick;

      span {
        color: $ui-text-white;
      }
    }

    &.button-gray {
      border: 1px solid $gray;
      background-color: $gray;

      span {
        color: $ui-text-white;
      }
    }

    &.button-dark {
      border: 1px solid $dark;
      background-color: $dark;

      span {
        color: $ui-text-white;
      }
    }

    &.button-white {
      border: 1px solid $ui-line-gray;
      background-color: $ui-btn-white;

      span {
        color: $ui-text-dark;
      }
    }
  }
}

/* desktop */
@media (pointer: fine) {
  .check-button {
    &:hover {
      cursor: pointer;
      background-color: $ui-bg-gray;
      transition: all 0.3s ease-out 0s;
    }
  }
}
