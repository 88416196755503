@import "../../styles/colors.scss";

.btn {
  height: 52px;
  border-radius: 30px;
  transition: all 0.35s ease-out 0s;

  svg {
    margin-right: 8px;
  }

  &.btn-blue-light {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    border: 1px solid $primary;
    background-color: $ui-bg-white;

    span {
      color: $primary;
      text-transform: uppercase;
      transition: all 0.35s ease-out 0s;
    }

    &.active {
      background-color: rgba($blue, 0.6);
      transition: all 0.35s ease-out 0s;

      span {
        color: $black;
        transition: all 0.35s ease-out 0s;
      }
    }

    &.fill {
      background-color: $primary;

      span {
        color: $ui-text-white;
      }
    }
  }

  &.btn-orange-light {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    border: 1px solid $amber;
    background-color: $ui-bg-white;

    span {
      color: $ui-text-orange;
      text-transform: uppercase;
      transition: all 0.35s ease-out 0s;
    }

    &.active {
      background-color: rgba($orange, 0.6);
      transition: all 0.35s ease-out 0s;

      span {
        color: $amber;
        transition: all 0.35s ease-out 0s;
      }
    }
  }

  &.btn-orange {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    background-color: $orange;

    span {
      color: $ui-text-white;
      text-transform: uppercase;
      transition: all 0.35s ease-out 0s;
    }

    &.active {
      background-color: rgba($orange, 0.6);
      transition: all 0.35s ease-out 0s;

      span {
        color: $amber;
        transition: all 0.35s ease-out 0s;
      }
    }
  }

  &.btn-pink {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    border: 1px solid $amber;
    background-color: $ui-bg-white;

    span {
      color: $amber;
      text-transform: uppercase;
      transition: all 0.35s ease-out 0s;
    }

    &.active {
      border-color: rgba($amber, 0.6);
      transition: all 0.35s ease-out 0s;

      span {
        color: $amber;
        transition: all 0.35s ease-out 0s;
      }
    }
  }

  &.btn-gray {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    background-color: $ui-bg-gray;

    span {
      color: $ui-text-white;
      text-transform: uppercase;
    }
  }

  &.btn-green {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    background-color: $green;

    span {
      color: $ui-text-white;
      text-transform: uppercase;
    }

    &.active {
      background-color: rgba($green, 0.6);
      transition: all 0.35s ease-out 0s;

      span {
        color: $ui-text-green;
        transition: all 0.35s ease-out 0s;
      }
    }
  }

  &.btn-light {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    background-color: $ui-bg-light;

    span {
      color: $ui-text-dark;
      text-transform: uppercase;
    }
  }

  &.btn-white {
    align-items: center;
    justify-content: center;
    padding: 0 52px;
    background-color: $ui-bg-white;

    span {
      color: $darkGray;
      text-transform: uppercase;
    }
  }

  &.disabled {
    background-color: $ui-bg-gray;
  }

  &.small {
    height: 32px;
    padding: 0 16px;
    border-radius: 16px;

    span {
      text-transform: none;
    }
  }
}

@media (pointer: fine) {
  .btn {
    &.btn-orange-light {
      &:hover {
        background-color: $amber;
        transition: all 0.35s ease-out 0s;

        span {
          color: $ui-text-white;
        }

        svg {
          fill: $ui-text-white;
        }
      }
    }

    &.btn-pink {
      &:hover {
        border-color: rgba($amber, 0.4);
        transition: all 0.35s ease-out 0s;

        span {
          color: $amber;
          transition: all 0.35s ease-out 0s;
        }
      }
    }

    &.btn-green {
      &:hover {
        background-color: rgba($green, 0.4);
        transition: all 0.35s ease-out 0s;

        span {
          color: $ui-text-green;
          transition: all 0.35s ease-out 0s;
        }
      }
    }
  }
}
