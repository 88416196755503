@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.table-card {
  border-bottom: 1px solid $ui-line-table;

  &:hover {
    background-color: $gray4;
  }

  .table-card-col {
    padding: 12px 8px;

    .table-card-col-inner {
      position: relative;
      flex-direction: row;
      align-items: center;
    }

    .table-card-sorting {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .table-card-sorting-button {
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 4px 0;
        border-radius: 6px;
        background-color: $gray2;

        .table-card-sorting-button-icon {
          width: 8px;
          height: 8px;
          fill: #fff;

          &.up {
            transform: rotate(180deg);
          }
        }
      }

      .table-card-sorting-input {
        height: 28px;
        text-align: center;
      }
    }

    .table-card-button {
      align-self: flex-start;
      padding: 6px;
      border-radius: 6px;
      background-color: $gray2;

      span {
        color: $ui-text-light;
      }

      .table-card-button-icon {
        width: 16px;
        height: 16px;
        fill: white;

        &.red {
          fill: $brick;
        }
        &.amber {
          fill: $amber;
        }
        &.gray {
          fill: $gray;
        }
  
        &.blue {
          fill: $blue;
        }
  
  
        &.teal {
          fill: $teal;
        }
  
        &.green {
          fill: $green;
        }
  
        &.orange {
          fill: $amber;
        }
  
        &.red {
          fill: $brick;
        }
  
        &.cyan {
          fill: $cyan;
        }
  
        &.sky {
          fill: $sky;
        }
  
        &.violet {
          fill: $purple;
        }
  
      }

      &.gray {
        background-color: $gray;
      }

      &.blue {
        background-color: $blue;
      }


      &.teal {
        background-color: $teal;
      }

      &.green {
        background-color: $green;
      }

      &.orange {
        background-color: $amber;
      }

      &.red {
        background-color: $brick;
      }

      &.cyan {
        background-color: $cyan;
      }

      &.sky {
        background-color: $sky;
      }

      &.violet {
        background-color: $purple;
      }

    }

    span {
      color: $ui-text-dark;
      font-size: 13px;
      font-weight: 400;

      &.gray {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $gray4;
        color: $gray;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }

      &.green {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $green;
        color: $ui-text-white;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }

      &.orange {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $amber;
        color: $ui-text-white;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }

      &.red {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $brick;
        color: $ui-text-white;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }

      &.cyan {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $cyan;
        color: $ui-text-white;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }

      &.sky {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $sky;
        color: $ui-text-white;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }

      &.violet {
        padding: 4px 8px;
        border-radius: 4px;
        background-color: $purple;
        color: $ui-text-white;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
      }

      &.link {
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.35s ease-out 0s;

        &:hover {
          color: $sky;
          transition: all 0.35s ease-out 0s;
        }
      }
    }

    .table-card-title {
      display: none;
      margin-bottom: 3px;
      color: $ui-text-dark;
      font-size: 11px;
      font-weight: 700;
    }

    .table-card-sort {
      flex-grow: 0;
      flex-shrink: 0;
      width: 6px;
      height: 6px;
      margin-left: 8px;
      margin-bottom: 2px;
    }

    .table-card-info {
      width: 12px;
      height: 12px;
      margin-left: 8px;
      margin-bottom: 4px;
      fill: $ui-icon-menu;
    }

    &.blue {
      &>span {
        color: $blue;
      }
    }

    &.red {
      &>span {
        color: $red;
      }
    }

    .status-dropdown {
      position: relative;

      .status-button {
        position: static;
      }

      .status-dropdown-list {
        position: absolute;
        top: 23px;
        z-index: 1111;
        border-radius: 4px;
        background-color: $ui-bg-white;
        box-shadow: 0 0 8px rgba(110, 165, 227, 0.4);

        .status-dropdown-list-wrap {
          max-height: 200px;
          padding: 4px 0;
          background-color: $ui-bg-white;
          overflow: hidden;
          overflow-y: scroll;
        }

        span {
          cursor: pointer;
          padding: 4px 12px;
          white-space: nowrap;
          transition: all 0.15s ease-out 0s;

          &:hover {
            background-color: $gray4;
            transition: all 0.15s ease-out 0s;
          }
        }
      }
    }
  }

  &:last-of-type {
    border-bottom-width: 0;
  }

  &.title {
    cursor: pointer;

    .table-card-col {
      .table-card-title {
        display: flex;
      }
    }
  }

  &.colored {
    border-top: 2px solid rgba($gray2, 0.35);
    border-bottom: 2px solid rgba($gray2, 0.35);
    background-color: rgba($blue, 0.25);

    &:first-child {
      border-top-width: 0;
      background-color: $ui-bg-light !important;
    }

    .table-card-col {
      vertical-align: middle;
      padding: 20px 20px 30px 20px;

      .table-card-title {
        margin-top: 0;
        font-size: 18px;
        font-weight: 400;
      }

      span {
        font-size: 18px;
      }
    }

    &.blue {
      background-color: rgba($blue, 0.2);
    }

    &.green {
      background-color: rgba($green, 0.4);
    }

    &.yellow {
      background-color: rgba($yellow, 0.3);
    }

    &.violet {
      background-color: rgba($purple, 0.3);
    }

    &.white {
      background-color: rgba($white, 1);
    }
  }
}

.blueBg {
  background-color: rgba(110, 165, 227, 0.4) !important;
}

@media all and (max-width: $ipad) {
  .table-card.static {
    .table-card-col {
      padding: 1px;
    }

    .table-card-title {
      font-size: 10px !important;
    }

    .table-card-col span {
      font-size: 10px !important;
    }
  }

  .table-card:not(.static) {
    display: grid;
    align-items: flex-start;
    padding: 8px 18px;
    border-bottom-width: 0;

    &.grid-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid-4 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-5 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-6 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-7 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-8 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-9 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-10 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-11 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-12 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-13 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-14 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-15 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-16 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.title {
      display: none;

      .table-card-col {
        display: none;
      }
    }

    .table-card-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 20px;

      .table-card-title {
        display: flex;
      }
    }

    &:nth-child(2n) {
      background-color: $ui-bg-light;
    }
  }
}

@media all and (max-width: $mobile) {
  .table-card:not(.static) {
    padding: 8px 0;

    .table-card-title {
      margin-bottom: 2px;
    }

    &.grid-3 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-5 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-7 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-8 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-9 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-10 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-11 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }

    &.grid-12 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }

    &.grid-13 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(7, 1fr);
    }

    &.grid-14 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(7, 1fr);
    }

    &.grid-15 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr);
    }

    &.grid-16 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr);
    }
  }
}

@media all and (max-width: 380px) {
  .table-card:not(.static) {
    padding: 6px 0;

    &.grid-14 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(14, 1fr);
    }

    &.grid-15 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(15, 1fr);
    }

    &.grid-16 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(16, 1fr);
    }

    .table-card-col {
      padding: 0 16px;
    }
  }
}