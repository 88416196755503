@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.alert-city-list {
  width: 100%;
  padding: 8px 0 20px 0;

  .alert-city-item {
    padding: 6px 0;

    span {
      color: $ui-text-dark;
    }

    &.active {
      span {
        color: $ui-text-orange;
        font-weight: 600;
      }
    }
  }
}