@import "../../../styles/colors.scss";
@import "../../../styles/media.scss";

.board-title {
  position: relative;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 320px;
  height: 57px;
  margin-bottom: 12px;
  padding-left: 20px;
  padding-right: 16px;
  border-radius: 6px;
  background: url("../../../assets/images/board/block.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;

  .board-title-info {
    flex-grow: 1;
    flex-shrink: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 57px;
      background-color: $dark;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      color: $ui-text-gray;
      font-size: 14px;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .board-title-button {
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    height: 32px;
    border-radius: 8px;
    transition: all 0.3s ease-out 0s;

    .board-title-button-icon {
      width: 20px;
      height: 20px;
      fill: $ui-icon-gray;
    }
  }

  /* colors line */
  &.red {
    .board-title-info::before {
      background-color: $red;
    }
  }
  &.pink {
    .board-title-info::before {
      background-color: $amber;
    }
  }
  &.purple {
    .board-title-info::before {
      background-color: $purple;
    }
  }
  &.violet {
    .board-title-info::before {
      background-color: $violet;
    }
  }
  &.primary {
    .board-title-info::before {
      background-color: $primary;
    }
  }
  &.blue {
    .board-title-info::before {
      background-color: $blue;
    }
  }
  &.sky {
    .board-title-info::before {
      background-color: $sky;
    }
  }
  &.cyan {
    .board-title-info::before {
      background-color: $cyan;
    }
  }
  &.teal {
    .board-title-info::before {
      background-color: $teal;
    }
  }
  &.grass {
    .board-title-info::before {
      background-color: $grass;
    }
  }
  &.green {
    .board-title-info::before {
      background-color: $green;
    }
  }
  &.lime {
    .board-title-info::before {
      background-color: $lime;
    }
  }
  &.yellow {
    .board-title-info::before {
      background-color: $yellow;
    }
  }
  &.amber {
    .board-title-info::before {
      background-color: $amber;
    }
  }
  &.orange {
    .board-title-info::before {
      background-color: $orange;
    }
  }
  &.brick {
    .board-title-info::before {
      background-color: $brick;
    }
  }
  &.gray {
    .board-title-info::before {
      background-color: $gray;
    }
  }
  &.dark {
    .board-title-info::before {
      background-color: $dark;
    }
  }
}

/* desktop */
@media (pointer: fine) {
  .board-title {
    .board-title-button {
      &:hover {
        background-color: $ui-bg-gray;
        transition: all 0.3s ease-out 0s;
      }
    }
  }
}
