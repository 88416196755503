@import "../../styles/colors.scss";

.svg-icon {
  flex-grow: 0;
  flex-shrink: 0;
  
  /* colors */
  &.gray {
    fill: $ui-icon-gray;
  }

  &.blue-light {
    fill: $primary;
  }

  &.orange-light {
    fill: $amber;
  }

  &.green {
    fill: $green;
  }

  &.light {
    fill: $ui-bg-light;
  }

  &.orange {
    fill: $ui-text-orange;
  }

  &.pink {
    fill: $amber;
  }

  &.white {
    fill: $ui-icon-white;
  }

  &.whatsapp {
    fill: #009a00;
  }

  &.telegram {
    fill: #00abff;
  }

  &.vk {
    fill: #0078ff;
  }

  &.instagram {
    fill: #d6249f;
  }

  /* sizes */
  &.s8 {
    width: 8px;
    height: 8px;
  }

  &.s10 {
    width: 10px;
    height: 10px;
  }

  &.s12 {
    width: 12px;
    height: 12px;
  }

  &.s16 {
    width: 16px;
    height: 16px;
  }

  &.s18 {
    width: 18px;
    height: 18px;
  }

  &.s20 {
    width: 20px;
    height: 20px;
  }

  &.s24 {
    width: 24px;
    height: 24px;
  }

  &.s28 {
    width: 28px;
    height: 28px;
  }
}
