@import "../../styles/colors.scss";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: $ui-bg-modal;
  overflow-y: auto;

  &.full {
    padding: 0;
    
    .modal-inner {
      align-items: center;
      justify-content: center;
      max-width: 100%;
      height: 100%;
    }
  }

  &.light {
    background-color: $ui-bg-slide;
  }
}

.modal.show {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0s ease-out 0s;

  .modal-inner {
    opacity: 1;
  }
}

.modal.hide {
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0s ease-out 0s;

  .modal-inner {
    opacity: 0;
  }
}

.modal.scroll {
  align-items: center;
  justify-content: flex-start;
  //height: auto;
}

.modal.center {
  align-items: center;
}

.modal-inner {
  position: relative;
  width: 100%;
  max-width: 1024px;
  min-width: 620px;
  transition: all 0.35s ease-out 0.05s;
}

/* close button */
.modal-close-btn {
  position: absolute;
  right: -48px;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $ui-bg-white;

  .modal-close-btn-icon {
    width: 20px;
    height: 20px;
    fill: $ui-icon-dark;
    transition: all 0.35s ease-out 0s;
  }

  &:hover .modal-close-btn-icon {
    width: 22px;
    height: 22px;
    transition: all 0.35s ease-out 0s;
  }
}

.modal-dates {
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  overflow-x: auto;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .modal-dates-scroll {
    flex-direction: row;
    width: fit-content;
    gap: 12px;

    .modal-dates-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 54px;
      border-radius: 16px;
      background-color: $ui-bg-light;

      span {
        color: $ui-text-dark;

        b {
          color: $ui-text-black;
          font-weight: 500;
        }
      }

      &.active {
        background-color: $ui-bg-blue-light;
      }
    }
  }
}

/* ALERT */
.alert {
  position: relative;
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 420px;

  .alert-body {
    align-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 20px;
    background-color: $ui-bg-white;

    .alert-icon {
      width: 32px;
      height: 32px;
      margin-top: 4px;
      margin-bottom: 16px;
      fill: $ui-icon-dark;
    }

    h3 {
      margin-bottom: 4px;
      color: $ui-text-black;
      text-align: center;
    }

    p {
      margin-bottom: 16px;
      color: $ui-text-dark2;
      text-align: center;
    }

    .alert-button {
      width: 100%;
    }
  }
}

/* LOADER */

.loader {
  align-self: center;
  justify-self: center;
}

.loader-gif {
  width: 32px;
  height: 32px;
}

.modal-body {
  flex-direction: column;
  width: 100%;
  padding: 20px;
  background-color: $ui-bg-light;
  box-shadow: 0 0 15px $ui-shadow;
}

@media all and (max-width: 1080px) {
  .modal {
    padding: 16px;
  }

  .modal-close-btn {
    right: 4px;
    top: 4px;
    z-index: 9;
    background-color: $ui-bg-light;
  }
}

@media all and (max-width: 991px) {
  .modal {
    align-items: flex-start;
  }

  .modal-inner {
    width: 100%;
  }
}

@media all and (max-width: 768px) {
  .modal-inner {
    min-width: 280px;
  }

  .modal-body {
    padding: 8px;
  }
}
